// import { useEffect } from 'react';
import './App.css';

function App() {
  // useEffect(() => {
  //   const requestVideo = (videoUrl, {
  //     type,
  //     start,
  //     end
  //   }) => {
  //     let xhr = new XMLHttpRequest()
  //     xhr.setRequestHeader("range")
  //     xhr.open("GET", videoUrl)
  //     xhr.send()
  //   }
  // })

  return (
    <div>
      <h1>Video Straming Test</h1>
      <div style={{ display: "flex" }}>
        <video muted controls>
          <source src='http://127.0.0.1:8080/video'></source>
        </video>
        <video muted controls>
          <source src='https://whale.in.th/pv/cgi-bin/video.mp4'></source>
        </video>
      </div>
    </div>
  );
}

export default App;
